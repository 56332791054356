import { useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Alert } from 'antd';

const PaymentStatus = () => {
  const stripe = useStripe();
  const [alert, setAlert] = useState({ type: 'info', message: 'processing' });

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (clientSecret) {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (paymentIntent) {
          switch (paymentIntent.status) {
            case 'succeeded':
              setAlert({ type: 'success', message: 'Success! Payment received.' });
              break;

            case 'processing':
              setAlert({ type: 'info', message: "Payment processing. We'll update you when payment is received." });
              break;

            case 'requires_payment_method':
              setAlert({ type: 'error', message: 'Payment failed. Please try another payment method.' });
              break;

            default:
              setAlert({ type: 'error', message: 'Something went wrong.' });
              break;
          }
        }
      });
    }
  }, [stripe]);

  return <Alert type={alert.type as any} message={alert.message} />;
};

export default PaymentStatus;
